import React, { useEffect, useRef, useState } from "react";
import PageTable from "@/components/i-pageTable/i-pageTable";
import PageTab from "@/components/i-pageTab/i-pageTab";

import { userOrderList, userOrderAudit, orderTranslate, orderExport } from "@/api/shopping";
import { Button, message, Modal, Space, Tag, Image } from "antd";
import { resetForm, setIcons,downloadFile, getTimeFormat } from "@/utils/common";

import { useSelector, useDispatch, useStore } from "react-redux";
import { dictInfoAsync } from "@/stores/dictInfo";

const useStatus = [
  {
    label: "待处理",
    value: "10000001",
  },
  {
    label: "处理中",
    value: "10000002",
  },
  {
    label: "通过",
    value: "10000003",
  },
  {
    label: "驳回",
    value: "10000004",
  },
  {
    label: "审核挂起",
    value: "10000005",
  },
] 


// 账户类型:1佣金，2积分 3转增账户 4充电收益
const cashType = [
  {
    label: "佣金金额",
    value: "0",
  },
  {
    label: "充电收益",
    value: "1",
  },
  {
    label: "代理收益",
    value: "2",
  },
]

const UserWithdrawal = () => {
  const dispatch = useDispatch();
  const {
    state: { approval_state, card_type, ns_channel_type },
  } = useSelector((state: { dictInfo: any }) => state.dictInfo);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [renterOpen, setRenterOpen] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  
  const [pageData, setPageData] = useState<
    SelectCmsUserCashOrderPageRes["rows"]
  >([]);
  const [formData, setFormData] = useState<any>({
    endTime: "",
    startTime: "",
    ypageNum: 1,
    ypageSize: 10,
  });
  const [modalFormData, setModalFormData] = useState<AddVipGoodsParams>({});

  const footer = [
    {
      title: "导出",
      icon: setIcons("DownloadOutlined"),
      onClick: () => exportExcel(),
      loading: exportLoading,
    },
  ];

  const formTab = [
    {
      label: "用户Id",
      name: "userId",
      type: "input",
    },
    {
      label: "订单号",
      name: "id",
      type: "input",
    },
    {
      label: "账号",
      name: "mobile",
      type: "input",
    },
    {
      label: "提现类型",
      name: "cashType",
      type: "select",
      options: cashType,
    },
    {
      label: "状态",
      name: "status",
      type: "select",
      options: useStatus,
    },
    {
      label: "时间范围",
      name: "rangePicker",
      type: "rangePicker",
      width: "380px",
    },
  ];

  const modalFormTab = [
    {
      label: "提现单号",
      name: "id",
      type: "text",
      width: "220px",
      labelCol: { span: 0, offset: 1 },
      params: {
        text: modalFormData.id,
      },
    },
    // {
    //   label: "纳税渠道",
    //   name: "nsChannelType",
    //   type: "select",
    //   options: ns_channel_type,
    //   rules: [{ required: true, message: "请选择纳税渠道" }],
    // },
    // },
  ];

  const columns = [
    {
      title: "订单号",
      dataIndex: "id",
      key: "id",
      // width: "220px",
    },
    {
      title: "用户id",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "姓名",
      dataIndex: "userCardDto",
      key: "userCardDto",
      render: (userCardDto: {realName: string}) =>
         ( <div >
            {userCardDto?.realName || '-'}
          </div>
        )
    },
    {
      title: "账号",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "提现金额",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "身份证号",
      dataIndex: "userCardDto",
      key: "userCardDto",
      render: (userCardDto: {identityNumber: string}) =>
      ( <div color="green">
         {userCardDto?.identityNumber || '-'}
       </div>
     )
    },
    {
      title: "卡类型",
      dataIndex: "userCardDto",
      key: "userCardDto",
      render: (userCardDto: {cardTypeStr: string}) =>
         ( <Tag color="green">
            {userCardDto?.cardTypeStr || '-'}
          </Tag>
        )
    },
    {
      title: "银行卡名称",
      dataIndex: "userCardDto",
      key: "userCardDto",
      render: (userCardDto: {bankName: string}) =>
      ( <div color="green">
         {userCardDto?.bankName || '-'}
       </div>
     )
    },
    {
      title: "提现类型",
      dataIndex: "cashType",
      key: "cashType",
      render: (text: string) =>  (
        <Tag color="green">{cashType.find((i: { value: string }) => i.value == text)?.label}</Tag>
      ),
    },
    {
      title: "银行卡号",
      dataIndex: "userCardDto",
      key: "userCardDto",
      render: (userCardDto: {bankcardNum: string}) =>
      ( <div color="green">
         {userCardDto?.bankcardNum || '-'}
       </div>
     )
    },
    {
      title: "支付宝账号",
      dataIndex: "userCardDto",
      key: "userCardDto",
      render: (userCardDto: {alipayPaymentAccount: string}) =>
      ( <div color="green">
         {userCardDto?.alipayPaymentAccount || '-'}
       </div>
     )
    },
    {
      title: "第三方服务费",
      dataIndex: "serviceAmount",
      key: "serviceAmount",
    },
    {
      title: "第三方服务费率",
      dataIndex: "rate",
      key: "rate",
    },
    {
      title: "用户真实到账金额",
      dataIndex: "settleAmount",
      key: "settleAmount",
    },
    // {
    //   title: "第三方总扣除费用",
    //   dataIndex: "totalMoney",
    //   key: "totalMoney",
    // },
    // {
    //   title: "纳税渠道码",
    //   dataIndex: "nsChannelType",
    //   key: "nsChannelType",
    //   render: (text: string) => (
    //     <Tag color="green">
    //       {
    //         ns_channel_type?.find((i: { value: string }) => i.value === text)
    //           ?.label
    //       }
    //     </Tag>
    //   ),
    // },
    {
      title: "状态",
      dataIndex: "statusDesc",
      key: "statusDesc",
      render: (text: string) => (
        <Tag color="green">
          {
            text
          }
        </Tag>
      ),
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "操作",
      dataIndex: "cashOrderId",
      key: "cashOrderId",
      fixed: "right",
      render: (val: string, rows: SelectCmsVipGoodsPageRes["rows"][0]) => (
        <Space size="small">
          {rows.status === "10000001" ? (
            <>
              <Button
                size="small"
                icon={setIcons("MoneyCollectOutlined")}
                type="text"
                onClick={() => {
                  setModalFormData(rows);
                  setOpen(true);
                }}
              >
                通过
              </Button>
              <Button
                size="small"
                icon={setIcons("StopOutlined")}
                type="text"
                onClick={() => {
                  setModalFormData(rows);
                  setRenterOpen(true);
                }}
              >
                驳回
              </Button>
            </>
          ) : (
            ""
          )}
          {rows.status === "10000002" && rows.nsChannelType === '10190004' ? (
            <>
              <Button
                size="small"
                icon={setIcons("ReloadOutlined")}
                type="text"
                onClick={async() => {
                  try {
                    await orderTranslate(val);
                    message.success("同步状态成功！");
                    getPageData();
                  } catch (error) {}
                }}
              >
                同步
              </Button>
            </>
          ) : (
            ""
          )}
        </Space>
      ),
    },
  ];

  const renterModalFormTab = [
    {
      label: "提现单号",
      name: "cashOrderId",
      type: "text",
      width: "380px",
      labelCol: { span: 0, offset: 1 },
      params: {
        text: modalFormData.cashOrderId,
      },
    },
    {
      label: "驳回原因",
      name: "rejectReason",
      type: "textArea",
      width: "320px",
      rules: [{ required: true, message: "请填写驳回原因" }],
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  useEffect(() => {
    !approval_state && dispatch(dictInfoAsync("approval_state") as any);
    !ns_channel_type && dispatch(dictInfoAsync("ns_channel_type") as any);
    !card_type && dispatch(dictInfoAsync("card_type") as any);
  }, []);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await userOrderList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const search = (val: any) => {
    setFormData({ ...formData, ...val, ypageNum: 1 });
  };


    const exportExcel = async () => {
    setExportLoading(true);
    try {
      const data = await orderExport(formData);
      downloadFile(
        data as any,
        "提现订单统计" +
          getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss")
      );
    } catch (error) {
    } finally {
      setExportLoading(false);
    }
  };


  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };

  const confirmData = async () => {
    try {
      await userOrderAudit({
        id: modalFormData.id,
        status: "10000003",
      });
      message.success("已通过");
      setOpen(false);
      getPageData();
    } catch (error) {}
  };

  const reset = () => {
    setFormData({
      ...resetForm(formData),
    });
  };

  const confirmRenter = async (val: { rejectReason: string }) => {
    try {
      await userOrderAudit({
        id: modalFormData.id,
        rejectReason: val.rejectReason,
        status: "10000004",
      });
      message.success("已驳回");
      setRenterOpen(false);
      getPageData();
    } catch (error) {}
  };

  return (
    <div>
      <Modal
        destroyOnClose
        open={renterOpen}
        title="驳回"
        width="28%"
        footer={<></>}
        onCancel={() => setRenterOpen(false)}
      >
        <div style={{ position: "relative" }}>
          <PageTab
            subFoot
            search={confirmRenter}
            formTab={renterModalFormTab}
            reset={() => setRenterOpen(false)}
          ></PageTab>
        </div>
      </Modal>
      <Modal
        destroyOnClose
        open={open}
        title="审核"
        width="28%"
        footer={<></>}
        onCancel={() => setOpen(false)}
      >
        <PageTab
          subFoot
          search={confirmData}
          reset={() => setOpen(false)}
          formTab={modalFormTab}
        ></PageTab>
      </Modal>
      <PageTab
        defaultTimer={[]}
        reset={reset}
        search={search}
        formTab={formTab}
        footer={footer}
      ></PageTab>
      <PageTable
        loading={loading}
        rowKey="id"
        pageChange={pageChange}
        data={pageData}
        columns={columns}
        pagination={{
          total: total,
          current: formData.ypageNum,
        }}
      ></PageTable>
    </div>
  );
};

export default UserWithdrawal;
