import React, { useEffect, useRef, useState } from "react";
import PageTab from "@/components/i-pageTab/i-pageTab";
import PageTable from "@/components/i-pageTable/i-pageTable";
import {
  dpStatisticsConfigList,
  dpStatisticsConfigDetail,
  dpStatisticsConfigAdd,
  dpStatisticsConfigEdit,
  dpStatisticsConfigDelete,
  fictitiousConfigDetail,
  fictitiousConfigEdit,
  fictitiousConfigAdd,
} from "@/api/other";
import { Button, Descriptions, message, Modal, Space, Switch, Tag, Input } from "antd";
import {
  customRequest,
  setIcons,
} from "@/utils/common";

import { dictInfoAsync } from "@/stores/dictInfo";
import { userEditFormTab, items, columns } from "./helps";
import dayjs from "dayjs";

const DpStatisticsManager = () => {
  const [pageData, setPageData] = useState<SelectCmsAppUserPageRes["rows"]>([]);
  const [userOutPageData, setUserOutPageData] = useState<
    ListUserAccountChangesByIdRes["rows"]
  >([]);
  const [mainPicUploadProgress, setMainPicUploadProgress] = useState(false);

  const [total, setTotal] = useState(0);
  const [userOutTotal, setUserOutTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [categoryList, setCategoryList] = useState([]);

  const [modalFormData, setModalFormData] = useState<any>(items);

  const [formData, setFormData] = useState<any>({
    ypageNum: 1,
    ypageSize: 10,
  });

  const [userOutFormData, setUserOutFormData] = useState({
    userId: "",
    ypageNum: 1,
    ypageSize: 10,
  });

  const [modalFormuserEdit, setModalFormuserEdit] = useState<any>({
    image: [],
  });

  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState<any>({});

  const userEditFormTabList = userEditFormTab(
    modalFormuserEdit,
    setModalFormuserEdit,
    mainPicUploadProgress,
    setMainPicUploadProgress,
    setIcons,
    customRequest
  );


  useEffect(() => {
    getPageData();
  }, [formData]);

  

  useEffect(() => {
    userOutFormData.userId && getUserOutList();
  }, [userOutFormData]);

  useEffect(() => {
    getFictitiousConfig();
  }, []);

  const getFictitiousConfig = async () => {
    const { data } = await fictitiousConfigDetail();
    console.log(data, "data");
    if (!data || Object.keys(data).length === 0) return;
    let newData: any = [];
    items!.forEach((item: any) => {
      newData.push({
        ...item,
        children: data[item.key]
      })
    });
    console.log(newData, "newData", items);

    setModalFormData(newData);
    const initialEditData: any = {};
    Object.keys(data).forEach(key => {
      initialEditData[key] = data[key];
    });
    setEditData(initialEditData);
  }

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await dpStatisticsConfigList({ ...formData, delFlag: "0" });
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const deteletDetail = async (val: string) => {
    Modal.confirm({
      title: "确定要删除当前配置吗？",
      onOk: async () => {
        await dpStatisticsConfigDelete(val);
        message.success("删除成功");
        getPageData();
      },
    });
  };
 

  const getUserOutList = async () => {
    try {
    } catch (error) {}
  };

  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };

  const outDetail = async (val: string) => {
    try {
      const { data } = await dpStatisticsConfigDetail(val);
      setModalFormuserEdit({
        ...data,
        statisticsDate: data.statisticsDate ? dayjs(data.statisticsDate) : null,
      });
      setOpen(true);
    } catch (error) {}
  };

  const confirmUser = async (val: any) => {
    try {
      let data;
      if (!modalFormuserEdit?.id) {
        data = await dpStatisticsConfigAdd({
          ...val,
          statisticsDate: val?.statisticsDate?.format("YYYY-MM-DD"),
        });
      } else {
        data = await dpStatisticsConfigEdit({
          ...val,
          statisticsDate: val?.statisticsDate?.format("YYYY-MM-DD"),
        });
      }
      message.success(data.msg);
      setOpen(false);
      getPageData();
      setModalFormuserEdit({
        image: [],
      });
    } catch (error) {}
  };

  const resetEditUser = () => {
    setModalFormuserEdit({
      image: [],
    });
    setOpen(false);
  };

  return (
    <div>
      {/* <Descriptions
        bordered
        title="虚拟配置"
        size={"small"}
        extra={
          isEditing ? (
            <Space>
              <Button onClick={() => setIsEditing(false)}>取消</Button>
              <Button type="primary" onClick={handleSave}>
                保存
              </Button>
            </Space>
          ) : (
            <Button type="primary" onClick={() => setIsEditing(true)}>
              编辑
            </Button>
          )
        }
        items={modalFormData.map((item: any) => ({
          ...item,
          children: isEditing ? (
            <Input
              value={editData[item.key]}
              onChange={(e) => 
                setEditData((prev: any) => ({
                  ...prev,
                  [item.key]: e.target.value
                }))
              }
            />
          ) : (
            item.children
          )
        }))}
        column={4}
      /> */}
      
      <Modal
        destroyOnClose={true}
        open={open}
        title="大屏统计配置"
        width="380px"
        footer={<></>}
        onCancel={resetEditUser}
      >
        <PageTab
          subFoot
          search={confirmUser}
          reset={resetEditUser}
          formTab={userEditFormTabList as any}
          initialValues={modalFormuserEdit}
        ></PageTab>
      </Modal>
      <div style={{ marginTop: "24px" }}>
      <Button
        icon={setIcons("PlusOutlined")}
        type="primary"
        onClick={() => setOpen(true)}
      >
        新增
      </Button>
      </div>
      <PageTable
        loading={loading}
        pageChange={pageChange}
        size="small"
        rowKey="id"
        columns={columns(outDetail, deteletDetail)}
        data={pageData}
        pagination={{
          current: formData.ypageNum!,
          pageSize: formData.ypageSize!,
          total: total,
        }}
      ></PageTable>
    </div>
  );
};

export default DpStatisticsManager;
