import React, { useEffect, useRef, useState } from "react";
import PageTable from "@/components/i-pageTable/i-pageTable";
import PageTab from "@/components/i-pageTab/i-pageTab";

import {
  shoppingOrderList,
  cmsRefund,
  doDeliver,
  shoppingOrderExport,
  benefitUse,
  getRewardDetail,
} from "@/api/shopping";
import { storeInfoBaseList } from "@/api/index";

import { Button, message, Modal, Space, Tag, Image } from "antd";
import {
  downloadFile,
  getTimeFormat,
  resetForm,
  setIcons,
} from "@/utils/common";

import { useSelector, useDispatch, useStore } from "react-redux";
import { dictInfoAsync } from "@/stores/dictInfo";
import IForm from "@/components/i-form/i-form";
import { rewardDetailColumns } from "./helps";

const notRefund = ["10007012", "10007011", "10007014", "10007016", "10007008"];

const OrderManager = () => {
  const dispatch = useDispatch();
  const {
    state: { goods_order_status, express_status, express_company_code },
  } = useSelector((state: { dictInfo: any }) => state.dictInfo);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [sendGoodsOpen, setSendGoodsOpen] = useState(false);
  const [offqybOpen, setOffqybOpen] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [rewardDetailData, setRewardDetailData] = useState<any>({
    rows: [],
    total: 0,
  });

  const [orderId, setOrderId] = useState("");
  const [orderAmount, setOrderAmount] = useState(0);
  const [storeInfoList, setStoreInfoList] = useState<
    {
      merId: string;
      merMobile: string;
      merName: string;
      userId: string;
    }[]
  >([]);

  const [pageData, setPageData] = useState<
    SelectCmsShoppingOrderPageRes["rows"]
  >([]);
  const [formData, setFormData] = useState<any>({
    categoryName: "",
    endTime: "",
    startTime: "",
    ypageNum: 1,
    ypageSize: 10,
  });

  const [rewardDetailFormData, setRewardDetailFormData] = useState<any>({
    ypageNum: 1,
    ypageSize: 10,
    businessId: "",
  });

  const footer = [
    {
      title: "导出",
      icon: setIcons("DownloadOutlined"),
      onClick: () => exportExcel(),
      loading: exportLoading,
    },
  ];

  const formTab = [
    {
      label: "订单号",
      name: "orderId",
      type: "input",
      // width: "340px",
    },
    {
      label: "用户账号",
      name: "mobile",
      type: "input",
    },
    {
      label: "用户Id",
      name: "userId",
      type: "input",
    },
    {
      label: "时间范围",
      name: "rangePicker",
      type: "rangePicker",
      width: "380px",
    },
  ];

  const modalFormTab = [
    {
      label: "退款金额",
      name: "refundAmount",
      type: "input",
      rules: [{ required: true, message: "请填写退款金额" }],
      params: {
        type: "number",
      },
    },
    {
      label: "退款原因",
      name: "refundReason",
      type: "textArea",
      rules: [{ required: true, message: "请填写退款原因" }],
      params: {
        placeholder: "请输入退款原因！",
        rows: 5,
        maxLength: 100,
        showCount: true,
      },
    },
  ];

  const sendGoodsModalFormTab = [
    {
      label: "快递名称",
      name: "expressCompanyCode",
      type: "select",
      width: "400px",
      labelCol: { span: 0, offset: 2 },
      rules: [{ required: true, message: "请选择快递" }],
      options: express_company_code,
    },
    {
      label: "快递单号",
      name: "expressNo",
      type: "input",
      labelCol: { span: 0, offset: 2 },
      rules: [{ required: true, message: "请填写快递单号" }],
      width: "400px",
    },
    {
      label: "发货经手人姓名",
      name: "deliverUserName",
      type: "input",
      width: "400px",
    },
    {
      labelCol: { span: 0, offset: 3 },
      label: "发货备注",
      name: "deliverRemark",
      type: "textArea",
      width: "400px",
      params: {
        placeholder: "请输入发货备注",
        rows: 5,
        maxLength: 100,
        showCount: true,
      },
    },
  ];

  const columns = [
    {
      title: "订单号",
      dataIndex: "orderId",
      key: "orderId",
      // width: "220px",
    },
    {
      title: "用户Id",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "用户账号",
      dataIndex: "mobile",
      key: "mobile",
    },

    {
      title: "产品名称",
      dataIndex: "productName",
      key: "productName",
      width: "200px",
    },
    {
      title: "购买数量",
      dataIndex: "num",
      key: "num",
    },
    {
      title: "设备数量",
      dataIndex: "deviceNum",
      key: "deviceNum",
    },

    {
      title: "订单金额",
      dataIndex: "payPrice",
      key: "payPrice",
    },
    {
      title: "订单状态",
      dataIndex: "orderStatus",
      key: "orderStatus",
      render: (val: string) => (
        <Tag color="green">{val == "0" ? "已支付" : "未支付"}</Tag>
      ),
    },
    {
      title: "是否分佣",
      dataIndex: "hasRewardStr",
      key: "hasRewardStr",
      render: (val: string) => (
        <Tag color={val == "是" ? "green" : "red"}>{val}</Tag>
      ),
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "操作",
      dataIndex: "orderId",
      key: "orderId",
      fixed: "right",
      render: (val: string, rows: any) => (
        <Space size="small">
          {/* <Button
            size="small"
            icon={setIcons("SnippetsOutlined")}
            type="text"
            onClick={() => {
              outDetail(rows);
            }}
          >
            详情
          </Button> */}
          <Button
            size="small"
            icon={setIcons("DollarOutlined")}
            type="text"
            onClick={() => {
              setOrderDollar(val);
            }}
          >
            分润详情
          </Button>
          {/* {rows.goodsExpressStatus === "10007005" ? (
            <Button
              size="small"
              icon={setIcons("SendOutlined")}
              type="text"
              onClick={() => {
                sendGoods(val);
              }}
            >
              发货
            </Button>
          ) : (
            <></>
          )} */}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  useEffect(() => {
    !goods_order_status && dispatch(dictInfoAsync("goods_order_status") as any);
    !express_status && dispatch(dictInfoAsync("express_status") as any);
    !express_company_code &&
      dispatch(dictInfoAsync("express_company_code") as any);
  }, []);

  useEffect(() => {
    getModalData();
  }, [rewardDetailFormData]);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await shoppingOrderList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const search = (val: any) => {
    setFormData({ ...formData, ...val, ypageNum: 1 });
  };
  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };

  const exportExcel = async () => {
    setExportLoading(true);
    try {
      const data = await shoppingOrderExport(formData);
      downloadFile(
        data as any,
        "商城订单统计" + getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss")
      );
    } catch (error) {
    } finally {
      setExportLoading(false);
    }
  };

  const outDetail = (val: any) => {
    try {
      const data = JSON.parse(val.productDetail);
      console.log(data, "datadatadata");

      Modal.info({
        width: "680px",
        okText: "好的",
        icon: null,
        closable: true,
        content: (
          <div style={{ overflowY: "auto", height: "600px" }}>
            <div style={{ fontWeight: "bold" }}>产品分类：</div>
            <div style={{ marginBottom: 6 }}>{data.categoryName}</div>
            <div style={{ fontWeight: "bold" }}>产品名称：</div>
            <div style={{ marginBottom: 6 }}>{data.productName}</div>
            <div style={{ fontWeight: "bold" }}>产品详情：</div>
            <div dangerouslySetInnerHTML={{ __html: data.details }} />
          </div>
        ),
      });
    } catch (error) {
      Modal.info({
        width: "64%",
        okText: "好的",
        icon: null,
        content: (
          <div>
            {/* <div >产品详情：</div> */}
            {/* <div/> */}
          </div>
        ),
      });
    }
  };

  const setOrderDollar = async (val: string) => {
    setRewardDetailFormData({ ...rewardDetailFormData, businessId: val, businessOrderType: '1' });
    setOffqybOpen(true);
  };

  const getModalData = async () => {
    try {
      const data: any = await getRewardDetail(rewardDetailFormData);
      setRewardDetailData({
        rows: data.rows,
        total: data.total,
      });
    } catch (error) {}
  };

  const reset = () => {
    setFormData({ ...resetForm(formData), dateType: 0 });
  };

  const getMerList = async (keyword: string) => {
    console.log(keyword, "keywordkeyword");
    try {
      const { data } = await storeInfoBaseList(keyword);
      setStoreInfoList(data);
    } catch (error) {}
  };

  return (
    <div>
      <Modal
        destroyOnClose
        open={offqybOpen}
        title="分润详情"
        width="980px"
        footer={<></>}
        onCancel={(_) => setOffqybOpen(false)}
      >
        <PageTable
          data={rewardDetailData.rows}
          columns={rewardDetailColumns}
          pagination={{
            total: rewardDetailData.total,
            current: rewardDetailFormData.ypageNum,
            // showSizeChanger: false,
            showQuickJumper: false,
          }}
          height="520px"
          pageChange={(current, pageSize) => {
            setRewardDetailFormData({
              ...rewardDetailFormData,
              ypageNum: current,
              ypageSize: pageSize,
            });
          }}
        />
      </Modal>
      <PageTab
        defaultTimer={[]}
        reset={reset}
        search={search}
        formTab={formTab}
        footer={footer}
      ></PageTab>
      <PageTable
        loading={loading}
        rowKey="orderId"
        pageChange={pageChange}
        data={pageData}
        columns={columns}
        pagination={{
          total: total,
          current: formData.ypageNum,
        }}
      ></PageTable>
    </div>
  );
};

export default OrderManager;
