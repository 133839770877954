import http from "@/utils/request";

// CMS文章-下拉文章分类列表
export const categorybaseList = (params: any): Promise<any> => {
  return http.post("/biz/article/category/baseList", params);
};

// CMS文章-文章分类新增
export const categoryAdd = (params: defaultParams): Promise<any> => {
  return http.post("/biz/article/category/add", params);
};

// CMS文章-文章分类列表
export const infobaseList = (params: defaultParams): Promise<any> => {
  return http.post("/biz/article/category/list", {...params, delFlag: "0"});
};
// CMS文章-文章分类列表
export const Bookclassificationdetail = (id: string): Promise<any> => {
  return http.get(`/biz/article/category/detail/${id}`)
};


// CMS文章-文章分类删除
export const categoryDelete = (id: string): Promise<any> => {
  return http.delete(`/biz/article/category/${id}`);
};

// CMS文章-文章分类修改
export const categoryEdit = (params: defaultParams): Promise<any> => {
  return http.post("/biz/article/category/edit", params);
};


// CMS文章-文章列表
export const bookList = (params: defaultParams): Promise<any> => {
  return http.post("/biz/article/info/list", params);
};

// CMS文章-文章新增
export const bookAdd = (params: defaultParams): Promise<any> => {
  return http.post("/biz/article/info/add", params);
};

// CMS文章-文章修改
export const bookEdit = (params: defaultParams): Promise<any> => {
  return http.post("/biz/article/info/edit", params);
};


// CMS文章-文章删除
export const bookDelete = (id: string): Promise<any> => {
  return http.delete(`/biz/article/info/${id}`);
};


// CMS文章-文章详情
export const bookDetail = (id: string): Promise<any> => {
  return http.get(`/biz/article/info/detail/${id}`);
};


//虚拟配置新增

// 虚拟配置详情
export const fictitiousConfigDetail = (): Promise<any> => {
  return http.get(`/fictitious/config/detail`);
};

// 虚拟配置新增
export const fictitiousConfigAdd = (params: defaultParams): Promise<any> => {
  return http.post("/fictitious/config/add", params);
};

// 虚拟配置修改
export const fictitiousConfigEdit = (params: defaultParams): Promise<any> => {
  return http.post("/fictitious/config/edit", params);
};







// 虚拟订单分页查询
export const fictitiousOrderList = (params: defaultParams): Promise<any> => {
  return http.post("/fictitious/order/list", params);
};

// 虚拟订单详情
export const fictitiousOrderDetail = (id: string): Promise<any> => {
  return http.get(`/fictitious/order/detail/${id}`);
};

// 虚拟订单新增
export const fictitiousOrderAdd = (params: defaultParams): Promise<any> => {
  return http.post("/fictitious/order/add", params);
};

// 虚拟订单修改
export const fictitiousOrderEdit = (params: defaultParams): Promise<any> => {
  return http.post("/fictitious/order/edit", params);
};

// 虚拟订单删除
export const fictitiousOrderDelete = (id: string): Promise<any> => {
  return http.delete(`/fictitious/order/${id}`);
};










// 大屏统计配置新增
export const dpStatisticsConfigAdd = (params: defaultParams): Promise<any> => {
  return http.post("/fictitious/statistic/config/add", params);
};

// 大屏统计配置列表
export const dpStatisticsConfigList = (params: defaultParams): Promise<any> => {
  return http.post("/fictitious/statistic/config/list", params);
};

// 大屏统计配置修改
export const dpStatisticsConfigEdit = (params: defaultParams): Promise<any> => {
  return http.post("/fictitious/statistic/config/edit", params);
};

// 大屏统计配置删除
export const dpStatisticsConfigDelete = (id: string): Promise<any> => {
  return http.delete(`/fictitious/statistic/config/${id}`);
};

// 大屏统计配置详情
export const dpStatisticsConfigDetail = (id: string): Promise<any> => {
  return http.get(`/fictitious/statistic/config/detail/${id}`);
};









