import { message } from "antd";
import axios, {
  AxiosStatic,
  AxiosInstance,
  AxiosResponse,
  AxiosHeaderValue,
} from "axios";
// import { baseURL } from "./baseURL";
import { getStorage, removeStorage, setStorage } from "./common";

axios.defaults.timeout = 100000;
// axios.defaults.baseURL = baseURL;

// 请求拦截器
const http: AxiosInstance = axios.create({
  baseURL: '/admin', // 代理地址
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: getStorage("Authorization") as AxiosHeaderValue,
  },
});

// axios实例拦截响应
http.interceptors.response.use(
  (res: AxiosResponse): Promise<AxiosResponse<any, any>> => {
    const promise = new Promise((response, reject) => {
      if (res.status === 200) {
        if (res.data.code === 200) {
          response(res.data);
        } else if (res.data.code === 401) {
          message.error("登录过期，请重新登录");
          removeStorage("Authorization");
          removeStorage("storeOpenKeys");
          const memberUserList = getStorage("memberUserList") || [];
          if (memberUserList?.length) {
            setStorage("memberUserList", []);
          }
          setTimeout(() => {
            window.location.href = origin + "#/login";
            window.location.reload();
          }, 300);
        } else if (res.data && !res.data.code) {
          response(res.data);
        } else if (res.data.code === 403) {
          if (res.config.url?.includes("/index/statistics/0")) {
            reject(res);
          } else {
            message.error("当前登录用户没有权限，请联系管理员获取权限");
            reject(res);
          }
        } else {
          reject(res);
          message.error(res.data.msg);
        }
      } else if (res.status === 403) {
        message.error("当前登录用户没有权限，请联系管理员获取权限");
        response(res.data);
      } else {
        message.error(res.status);
        reject(res);
      }
    });
    return promise as any;
  },
  // 请求失败
  (error: any) => {
    const { response } = error;
    console.log(response, 'responseresponse');
    
    if (response?.status === 403) {
      message.error("当前登录用户没有权限，请联系管理员获取权限");
    } else {
      message.error("系统异常");
      // window.location.href = origin + "#/login";
      // window.location.reload();
    }
    return Promise.reject(response.data);
  }
);

export default http;
