import { normFile, setIcons } from "@/utils/common";
import { Button, DescriptionsProps, Space } from "antd";

export function userEditFormTab(
  modalFormuserEdit: any,
  setModalFormuserEdit: any,
  mainPicUploadProgress: any,
  setMainPicUploadProgress: any,
  setIcons: any,
  customRequest: any
): any[] {
  return [
    {
      label: "订单金额",
      name: "orderAmount",
      type: "input",
      params: {
        type: "number",
      },
    },
    {
      label: "收入金额",
      name: "incomeAmount",
      type: "input",
      params: {
        type: "number",
      },
    },
    {
      label: "订单总数",
      name: "orderCount",
      type: "input",
      params: {
        type: "number",
      },
    },
    {
      label: "统计日期",
      name: "statisticsDate",
      type: "datePicker",
    },
    {
      label: "备注",
      name: "remark",
      type: "textArea",
    },
  ];
}

export const items: DescriptionsProps["items"] =  [

 
  {
    key: "deviceCount",
    label: "全部设备数量",
    children: "$60.00",
  },
  {
    key: "activateDeviceCount",
    label: "激活设备数量",
    children: "Cloud Database",
  },
  {
    key: "activateOnlineDeviceCount",
    label: "激活在线设备数量",
    children: "18:00:00",
  },
  {
    key: "activateOfflineDeviceCount",
    label: "激活离线设备数量",
    children: "Prepaid",
  },
 
  // {
  //   key: 'id',
  //   label: '主键',
  //   children: 'Prepaid',
  // },
  {
    key: "levelFourCount",
    label: "四级代理商数量",
    children: "Prepaid",
  },
  {
    key: "levelThreeCount",
    label: "三级代理商数量",
    children: "Prepaid",
  },

  {
    key: "levelTwoCount",
    label: "二级代理商数量",
    children: "Prepaid",
  },

  {
    key: "levelOneCount",
    label: "一级代理商数量",
    children: "Prepaid",
  },
];

export const columns = (outDetail: any, deteletDetail: any) => [

  {
    title: "订单金额",
    dataIndex: "orderAmount",
    key: "orderAmount",
  },

  {
    title: "收入金额",
    dataIndex: "incomeAmount",
    key: "incomeAmount",
  },
  {
    title: "订单总数",
    dataIndex: "orderCount",
    key: "orderCount",
  },
  {
    title: "统计日期",
    dataIndex: "statisticsDate",
    key: "statisticsDate",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    key: "createTime",
  },
  {
    title: "备注",
    dataIndex: "remark",
    key: "remark",
  },
  {
    title: "操作",
    dataIndex: "id",
    key: "id",
    fixed: "right",
    render: (val: string, rows: any) =>
      rows.delFlag !== "2" ? (
        <Space>
          <Button
            size="small"
            icon={setIcons("EditOutlined")}
            type="text"
            onClick={() => {
              outDetail(val);
            }}
          >
            修改
          </Button>
          <Button
            size="small"
            icon={setIcons("DeleteOutlined")}
            type="text"
            onClick={() => {
              deteletDetail(val);
            }}
          >
            删除
          </Button>
        </Space>
      ) : (
        <Space>
          {/* <Button
            size="small"
            icon={setIcons("PlayCircleOutlined")}
            type="text"
            onClick={() => {
              enableDetail(val);
            }}
          >
            启用
          </Button> */}
        </Space>
      ),
  },
];
