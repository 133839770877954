import { normFile, setIcons } from "@/utils/common";
import { Button, DescriptionsProps, Space } from "antd";

export function userEditFormTab(
  modalFormuserEdit: any,
  setModalFormuserEdit: any,
  mainPicUploadProgress: any,
  setMainPicUploadProgress: any,
  setIcons: any,
  customRequest: any
): any[] {
  return [
    {
      label: "用户头像",
      name: "image",
      type: "upload",

      getValueFromEvent: normFile,
      params: {
        maxCount: 1,
        fileList: modalFormuserEdit.image,
        beforeUpload: () => {
          setMainPicUploadProgress(true);
        },
        uploadMessage: mainPicUploadProgress ? (
          <div>
            {setIcons("LoadingOutlined")}
            <div>上传中...</div>
          </div>
        ) : (
          <div>
            {setIcons("PlusOutlined")}
            <div style={{ marginTop: 8 }}>上传</div>
          </div>
        ),
        customRequest: async (e: any) => {
          try {
            const data = await customRequest(
              e,
              modalFormuserEdit.image as any[]
            );

            setModalFormuserEdit({
              ...modalFormuserEdit,
              image: data as any,
            });
          } catch (error) {
          } finally {
            setMainPicUploadProgress(false);
          }
        },
        onRemove: () => {
          setModalFormuserEdit({
            ...modalFormuserEdit,
            image: [],
          });
        },
      },
    },
    {
      label: "用户昵称",
      name: "userNickName",
      type: "input",
    },
    {
      label: "订单编号",
      name: "orderNo",
      type: "input",
    },
 
    {
      label: "网点名称",
      name: "outletsName",
      type: "input",
    },
    {
      label: "订单金额",
      name: "orderAmount",
      type: "input",
      params: {
        type: "number",
      },
    },
    {
      label: "收入金额",
      name: "incomeAmount",
      type: "input",
      params: {
        type: "number",
      },
    },
    {
      label: "用户创建时间",
      name: "userCreateTime",
      type: "timePicker",
    },

    {
      label: "备注",
      name: "remark",
      type: "textArea",
    },
    // {
    //   label: "设备状态",
    //   name: "deviceStatus",
    //   type: "input",
    // },
    // {
    //   label: "订单状态",
    //   name: "orderStatus",
    //   type: "input",

    // },

    // {
    //   label: "状态",
    //   name: "delFlag",
    //   type: "radio",
    //   options: statusList,
    // },
  ];
}

export const items: DescriptionsProps["items"] =  [

 {
  key: "deviceCount",
  label: "全部设备数量",
  children: "$60.00",
 },

  {
    key: "activateDeviceCount",
    label: "激活设备数量",
    children: "Cloud Database",
  },
  {
    key: "activateOnlineDeviceCount",
    label: "激活在线设备数量",
    children: "18:00:00",
  },
  {
    key: "activateOfflineDeviceCount",
    label: "激活离线设备数量",
    children: "Prepaid",
  },
  {
    key: "levelFourCount",
    label: "四级代理商数量",
    children: "Prepaid",
  },
  {
    key: "levelThreeCount",
    label: "三级代理商数量",
    children: "Prepaid",
  },

  {
    key: "levelTwoCount",
    label: "二级代理商数量",
    children: "Prepaid",
  },

  {
    key: "levelOneCount",
    label: "一级代理商数量",
    children: "Prepaid",
  },

   {
    key: "totalAmount",
    label: "总成功金额",
    children: "$60.00",
   },
   {
    key: "dayAmount",
    label: "今日成功金额",
    children: "$60.00",
   },
];

export const columns = (outDetail: any, deteletDetail: any) => [
  {
    title: "用户头像",
    dataIndex: "userAvatar",
    key: "userAvatar",
    render: (data: string) =>
      data && <img style={{ height: "32px" }} src={data} alt="" />,
  },
  {
    title: "用户昵称",
    dataIndex: "userNickName",
    key: "userNickName",
  },

  {
    title: "订单编号",
    dataIndex: "orderNo",
    key: "orderNo",
  },
  {
    title: "网点名称",
    dataIndex: "outletsName",
    key: "outletsName",
  },

  {
    title: "订单金额",
    dataIndex: "orderAmount",
    key: "orderAmount",
  },

  {
    title: "收入金额",
    dataIndex: "incomeAmount",
    key: "incomeAmount",
  },
  {
    title: "备注",
    dataIndex: "remark",
    key: "remark",
  },
  {
    title: "用户创建时间",
    dataIndex: "userCreateTime",
    key: "userCreateTime",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    key: "createTime",
  },
  {
    title: "操作",
    dataIndex: "orderId",
    key: "orderId",
    fixed: "right",
    render: (val: string, rows: any) =>
      rows.delFlag !== "2" ? (
        <Space>
          <Button
            size="small"
            icon={setIcons("EditOutlined")}
            type="text"
            onClick={() => {
              outDetail(val);
            }}
          >
            修改
          </Button>
          <Button
            size="small"
            icon={setIcons("DeleteOutlined")}
            type="text"
            onClick={() => {
              deteletDetail(val);
            }}
          >
            删除
          </Button>
        </Space>
      ) : (
        <Space>
          {/* <Button
            size="small"
            icon={setIcons("PlayCircleOutlined")}
            type="text"
            onClick={() => {
              enableDetail(val);
            }}
          >
            启用
          </Button> */}
        </Space>
      ),
  },
];
