import { Modal, Input, AutoComplete } from 'antd';
import { useEffect, useRef, useState } from 'react';

interface MapsProps {
  visible: boolean;
  onCancel: () => void;
  onOk: (address: string, location: { lng: number; lat: number }) => void;
}

const Maps: React.FC<MapsProps> = ({ visible, onCancel, onOk }) => {
  const mapRef = useRef<any>(null);
  const markerRef = useRef<any>(null);
  const geocoderRef = useRef<any>(null);
  const autoCompleteRef = useRef<any>(null);
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    if (visible && !mapRef.current) {
      setTimeout(() => {
        const map = new (window as any).AMap.Map('mapContainer', {
          zoom: 5,
          center: [104.0668, 30.5728]
        });
        
        const marker = new (window as any).AMap.Marker({
          position: [104.0668, 30.5728],
          draggable: true,
          map: map
        });

        // 初始化插件
        (window as any).AMap.plugin(['AMap.Geocoder', 'AMap.AutoComplete'], () => {
          geocoderRef.current = new (window as any).AMap.Geocoder();
          autoCompleteRef.current = new (window as any).AMap.AutoComplete({
            city: ''
          });
        });

        map.on('click', (e: any) => {
          const lnglat = e.lnglat;
          marker.setPosition(lnglat);
          updateAddress(lnglat);
        });

        marker.on('dragend', (e: any) => {
          const lnglat = marker.getPosition();
          updateAddress(lnglat);
        });

        mapRef.current = map;
        markerRef.current = marker;
      }, 0);
    }
  }, [visible]);

  // 处理输入变化，获取提示列表
  const handleSearch = (value: string) => {
    setSearchValue(value);
    if (autoCompleteRef.current && value.trim()) {
      autoCompleteRef.current.search(value, (status: string, result: any) => {
        if (status === 'complete' && result.tips) {
          const newOptions = result.tips.map((tip: any) => ({
            value: tip.name,
            label: (
              <div>
                <span>{tip.name}</span>
                <span style={{ color: '#999', marginLeft: 8 }}>
                  {tip.district}
                </span>
              </div>
            ),
            address: tip.district + tip.name
          }));
          setOptions(newOptions);
        }
      });
    } else {
      setOptions([]);
    }
  };

  // 选择地址
  const handleSelect = (value: string, option: any) => {
    const address = option.address || value;
    if (geocoderRef.current) {
      geocoderRef.current.getLocation(address, (status: string, result: any) => {
        if (status === 'complete' && result.geocodes.length) {
          const lnglat = result.geocodes[0].location;
          mapRef.current.setCenter([lnglat.lng, lnglat.lat]);
          markerRef.current.setPosition([lnglat.lng, lnglat.lat]);
          updateAddress({ lng: lnglat.lng, lat: lnglat.lat });
        }
      });
    }
  };

  // 更新地址
  const updateAddress = (lnglat: any) => {
    if (geocoderRef.current) {
      geocoderRef.current.getAddress([lnglat.lng, lnglat.lat], (status: string, result: any) => {
        if (status === 'complete' && result.info === 'OK') {
          const address = result.regeocode.formattedAddress;
          setSearchValue(address);
        }
      });
    }
  };

  const handleOk = () => {
    if (markerRef.current) {
      const position = markerRef.current.getPosition();
      geocoderRef.current.getAddress([position.lng, position.lat], (status: string, result: any) => {
        if (status === 'complete' && result.info === 'OK') {
          const address = result.regeocode.formattedAddress;
          onOk(address, { lng: position.lng, lat: position.lat });
        }
      });
    }
  };

  return (
    <Modal
      title="选择位置"
      open={visible}
      onCancel={onCancel}
      onOk={handleOk}
      width={800}
      destroyOnClose
    >
      <AutoComplete
        value={searchValue}
        options={options}
        onSelect={handleSelect}
        onSearch={handleSearch}
        onChange={(value) => setSearchValue(value)}
        style={{ width: '100%', marginBottom: 16 }}
        placeholder="搜索地址"
      />
      <div id="mapContainer" style={{ height: '500px' }}></div>
    </Modal>
  );
};

export default Maps;