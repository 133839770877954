import React, { useEffect, useRef, useState } from "react";
import PageTab from "@/components/i-pageTab/i-pageTab";
import PageTable from "@/components/i-pageTable/i-pageTable";
import {
  setIcons,
  showModal,
  downloadFile,
  getTimeFormat,
  getFirstDay,
} from "@/utils/common";
import {
  merAndVipStatisticsList,
  merAndVipStatisticsExport,
} from "@/api/statistics";
import { Tag, Image } from "antd";

const MerAndVipStatisticsList = () => {
  const [pageData, setPageData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const [formData, setFormData] =
    useState<Record<string, any>>({
      startTime: getTimeFormat(getFirstDay(new Date()), "yyyy-MM-dd HH:mm:ss"),
      endTime: getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss"),
      mobile: "",
      ypageNum: 1,
      ypageSize: 10,
    });

  const formTab = [
    {
      label: "变动人账号",
      name: "mobile",
      type: "input",
    },
    // {
    //   label: "门店电话",
    //   name: "storeMobile",
    //   type: "input",
    // },
    {
      label: "资金走向类型",
      name: "accountTrend",
      type: "select",
      labelCol: { span: 10 },
      options: [
        {
          label: "赚取",
          value: 1,
        },
        {
          label: "消耗",
          value: 2,
        },
      ],
    },
    {
      label: "创建时间",
      name: "rangePicker",
      type: "rangePicker",
      width: "330px",
      params: {},
    },
  ];

  const footer = [
    {
      title: "导出",
      icon: setIcons("DownloadOutlined"),
      onClick: () => exportExcel(),
      loading: exportLoading,
    },
  ];

  const columns = [
    // {
    //   title: "用户Id",
    //   dataIndex: "userId",
    //   key: "userId",
    // },
    {
      title: "变动人账号",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "变动人昵称",
      dataIndex: "nickName",
      key: "nickName",
    },
    // {
    //   title: "用户头像",
    //   dataIndex: "avatar",
    //   key: "avatar",
    //   render: (val: string) => <Image width={50} height={50} src={val} />,
    // },

    {
      title: "资金类型",
      dataIndex: "amountTypeStr",
      key: "amountTypeStr",
      render: (val: string) => <Tag color="blue">{val}</Tag>,
    },
    {
      title: "业务订单类型",
      dataIndex: "businessOrderType",
      key: "businessOrderType",
    },
    {
      title: "交易金额",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "变动前资金",
      dataIndex: "beforeAmount",
      key: "beforeAmount",
    },
    {
      title: "变动后资金",
      dataIndex: "afterAmount",
      key: "afterAmount",
    },
    {
      title: "资金走向类型",
      dataIndex: "accountTrendStr",
      key: "accountTrendStr",
      render: (val: string) => <Tag color="blue">{val}</Tag>,
    },
    {
      title: "业务类型",
      dataIndex: "businessTypeStr",
      key: "businessTypeStr",
      render: (val: string) => <Tag color="blue">{val}</Tag>,
    },
    // {
    //   title: "创建者",
    //   dataIndex: "createBy",
    //   key: "createBy",
    // },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    }
  ];

  useEffect(() => {
    getPageData();
  }, [formData]);

  const getPageData = async () => {
    setLoading(true);
    try {
      const data = await merAndVipStatisticsList(formData);
      setPageData(data.rows);
      setTotal(data.total);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const exportExcel = async () => {
    setExportLoading(true);
    try {
      const data = await merAndVipStatisticsExport(formData);
      downloadFile(
        data as any,
        "用户--门店与推荐会员订单统计" +
          getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss")
      );
    } catch (error) {
    } finally {
      setExportLoading(false);
    }
  };
  const search = (val: any) => {
    setFormData({
      ...formData,
      ...val,
      ypageNum: 1,
    });
  };

  const reset = () => {
    setFormData({
      startTime: getTimeFormat(getFirstDay(new Date()), "yyyy-MM-dd HH:mm:ss"),
      endTime: getTimeFormat(new Date(), "yyyy-MM-dd HH:mm:ss"),
      mobile: "",
      ypageNum: 1,
      ypageSize: 10,
    });
  };

  const pageChange = (current: number, pageSize: number) => {
    setFormData({
      ...formData,
      ypageNum: current,
      ypageSize: pageSize,
    });
  };
  return (
    <div>
      <PageTab
        search={search}
        reset={reset}
        formTab={formTab}
        footer={[]}
      ></PageTab>
      <PageTable
        loading={loading}
        pageChange={pageChange}
        size="small"
        rowKey="id"
        columns={columns}
        data={pageData}
        pagination={{
          current: formData.ypageNum!,
          pageSize: formData.ypageSize!,
          total: total,
        }}
      ></PageTable>
    </div>
  );
};

export default MerAndVipStatisticsList;
