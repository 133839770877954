import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Radio,
  DatePicker,
  Space,
  TreeSelect,
  Tree,
  Upload,
  Cascader,
  TimePicker
} from "antd";
import styles from "../../static/css/index.module.css";
import {
  getFirstDay,
  getStorage,
  getTimeFormat,
  setIcons,
} from "../../utils/common";
import type { FormItemProps } from "antd";
import "dayjs/locale/zh-cn";
import locale from "antd/es/date-picker/locale/zh_CN";
import dayjs, { Dayjs } from "dayjs";
import { FormLayout } from "antd/es/form/Form";
import { SpaceSize } from "antd/es/space";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FormInstance } from "antd/lib";

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const quillModules = {
  toolbar: {
    container: [
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],
      [{ header: 1 }, { header: 2 }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ size: ["small", false, "large", "huge"] }],
      [{ color: [] }],
      [{ background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ["link", "image",],
    ],
  },
};
interface FormTabItem extends FormItemProps {
  type: string;
  width?: string;
  options?: any[];
  params?: Record<string, any>;
  notShow?: Boolean;
}

const IFormTab = (props: {
  content?: { data: string | number; type: string };
  formTab: FormTabItem[];
  subFoot?: boolean;
  search?: Function | undefined;
  reset?: React.FormEventHandler<HTMLFormElement> | undefined;
  footer?: any[];
  initialValues?: Record<string, any> | undefined;
  layout?: FormLayout;
  size?: SpaceSize | [SpaceSize, SpaceSize];
  gap?: string;
  defaultTimer?: any;
  form?: FormInstance;
}) => {
  const {
    formTab,
    subFoot,
    search,
    reset,
    footer,
    initialValues,
    layout = "inline",
    size = [8, 16],
    gap = "12px 10px",
    defaultTimer = [
      dayjs(getFirstDay(new Date())),
      dayjs(getTimeFormat(new Date()), "YYYY-MM-DD"),
    ],
    form: formProps,
  } = props;

  const [form] = Form.useForm();

  const finalForm = formProps || form;

  useEffect(() => {
    if (initialValues) {
      const decodedValues = { ...initialValues };
  
      // 遍历表单项，根据类型对数据进行解码
      formTab.forEach((item) => {
        if (subFoot) {
          item.labelCol = { span: 8, offset: 0 }
        }
        if (item.type === "quill" && initialValues[item.name as string]) {
          try {
            // Base64 解码并解码 URI 编码
            const encodedContent = initialValues[item.name as string];
 
            decodedValues[item.name as string] = decodeURIComponent(encodedContent);
          } catch (error) {
            console.error(`解码失败: ${item.name}`, error);
          }
        }
      });
  
      finalForm.setFieldsValue(decodedValues);
      console.log("解码后的初始值: ", decodedValues);
    }
  }, [finalForm, initialValues]);

  const onFinish = (values: any) => {
    const formData = {
      ...initialValues,
      ...values,
    };

    // Handle date picker values
    if (values?.datePicker) {
      values.datePicker = dayjs(values.datePicker);
    }
    if (values?.rangePicker && values?.rangePicker[0]) {
      const startTime = dayjs(values.rangePicker[0]).format("YYYY-MM-DD 00:00:00");
      const endTime = dayjs(values.rangePicker[1]).format("YYYY-MM-DD 23:59:59");
      delete formData.rangePicker;
      formData.startTime = startTime;
      formData.endTime = endTime;
    }

    // Handle rich text fields - encrypt content
    formTab.forEach(item => {
      if (item.type === 'quill' && formData[item.name as string]) {
        let content = formData[item.name as string].toString();

        content = encodeURIComponent(content);

        formData[item.name as string] = content;
      }   
    });
  
    console.log('处理后的表单数据：', formData);
    search && search(formData);
  };

  const setType = (item: FormTabItem) => {
    switch (item.type) {
      case "text":
        return <span {...item.params}>{item.params?.text || ""}</span>;
      case "input":
        return (
          <Input placeholder={"请输入" + item.label} {...item.params}></Input>
        );
      case "select":
        return (
          <Select
            placeholder="请选择"
            options={item.options?.length ? item.options : undefined}
            allowClear
            {...item.params}
          ></Select>
        );
      case "radio":
        return (
          <Radio.Group options={item.options} {...item.params}></Radio.Group>
        );
      case "button":
        return <Button {...item.params}>{item.name}</Button>;
      case "datePicker":
        return (
          <DatePicker
            locale={locale}
            format="YYYY-MM-DD"
            {...item.params}
          ></DatePicker>
        );
      case "rangePicker":
        return (
          <RangePicker
            defaultValue={defaultTimer}
            locale={locale}
            format="YYYY-MM-DD"
            {...item.params}
            allowClear={false}
          ></RangePicker>
        );
      case "timePicker":
        return <TimePicker format="HH:mm:ss" {...item.params}></TimePicker>;
      case "textArea":
        return <TextArea placeholder="请输入" {...item.params}></TextArea>;
      case "treeSelect":
        return <TreeSelect {...item.params}></TreeSelect>;
      case "cascader":
        return <Cascader placeholder="请选择" {...item.params}></Cascader>;
      case "quill":
        return (
          <ReactQuill
            modules={quillModules}
            placeholder="请输入"
            style={{
              width: item?.params?.width || "800px",
              minHeight: "300px",
            }}
            {...item.params}
          ></ReactQuill>
        );
      case "tree":
        return (
          <div
            style={{
              border: "1px solid #efefef",
              borderRadius: "4px",
              padding: "6px",
            }}
          >
            <Tree {...item.params}></Tree>
          </div>
        );
      case "upload":
        return (
          <Upload listType="picture-card" accept="image/*" {...item.params}>
            {initialValues?.[item.name as any]?.length <
            (item?.params?.maxCount as number)
              ? item.params?.uploadMessage
              : null}
          </Upload>
        );
      default:
        break;
    }
  };

  return (
    <>
      <Form
        form={finalForm}
        onFinish={onFinish}
        onReset={reset}
        layout={layout}
        // wrapperCol={{ span: 0, offset: 0 }}
        style={{ gap: gap }}
      >
        {formTab
          ?.filter((i) => !i?.notShow)
          ?.map((item, index) => (
            <Form.Item
              // labelCol={ !subFoot ? { span: 6, offset: 0 } : { span: 8, offset: 0 }}
              style={{ width: item.width || "260px", marginBottom: layout === "horizontal" ? "10px" : "auto" }}
              className={styles.formItem}
              {...item}
              key={index}
            >
              {setType(item)}
            </Form.Item>
          ))}
        {!subFoot && (
          <div>
            <Space size={size}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  搜索
                </Button>
              </Form.Item>
              <Form.Item>
                <Button htmlType="reset" type="default">
                  重置
                </Button>
              </Form.Item>
            </Space>
          </div>
        )}
        {subFoot && (
          <div className={styles.modalForm}>
            <Space>
              <Form.Item>
                <Button htmlType="reset" type="default">
                  取消
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  确认
                </Button>
              </Form.Item>
            </Space>
          </div>
        )}
      </Form>
      <Space align="start" className={styles.pageFooter}>
        {footer?.map((item, index) => {
          return item.render ? (
            item.render
          ) : (
            <Button {...item} key={index}>
              {item.title}
            </Button>
          );
        })}
      </Space>
    </>
  );
};
export default IFormTab;
