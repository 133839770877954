import http from "../utils/request";

// 分页查询首页经刚区图标
export const pageHomeList = (
  params: GetCmsPageHomeParams
): Promise<GetCmsPageHomeRes> => {
  return http.post("/cms/auth/pageHome/info/list", params);
};
// 新增图标功能
export const pageHomeAdd = (params: pageHomeAddParams): Promise<defaultRes> => {
  return http.post("/cms/auth/pageHome/info/add", params);
};
// 编辑图标功能
export const pageHomeEdit = (
  params: pageHomeAddParams
): Promise<defaultRes> => {
  return http.post("/cms/auth/pageHome/info/edit", params);
};

// 广告banner分页查询
export const appBannerList = (
  params: any
): Promise<any> => {
  return http.post("/sys/ad/list", params);
};

// 广告banner新增
export const appBannerAdd = (
  params: any
): Promise<any> => {
  return http.post("/sys/ad/add", params);
};


// 广告banner修改
export const appBannerEdit = (
  params: any
): Promise<any> => {
  return http.post("/sys/ad/edit", params);
};


// 分页查询用户中心菜单
export const appMenuList = (
  params: GetCmsUserCenterPageParams
): Promise<GetCmsUserCenterPageRes> => {
  return http.post("/cms/auth/user/menu/list", params);
};

// 运营商列表
export const operationList = (
  params: SelectCmsOperationCenterOperatorPageParams
): Promise<SelectCmsOperationCenterOperatorPageRes> => {
  return http.post("/cms/auth/operation/list", params);
};

// 新增运营商
export const operationAdd = (
  params: AddOperationCenterOperatorParams
): Promise<defaultRes> => {
  return http.post("/cms/auth/operation/add", params);
};

// 编辑运营商
export const operationEdit = (
  params: EditOperationCenterOperatorParams
): Promise<defaultRes> => {
  return http.post("/cms/auth/operation/edit", params);
};

// 启用-禁用运营商
export const operationNegationStatus = (
  operatorId: string
): Promise<defaultRes> => {
  return http.post("/cms/auth/operation/negationStatus", operatorId, {
    headers: {
      "Content-Type": "text/plain",
    },
  });
};

// 查询运营商列表

export const operationBaseList = (params: {
  operationCenterType?: string;
  operatorName?: string;
}): Promise<{
  code: 0;
  data: [
    {
      operationCenterType: "";
      operatorId: "";
      operatorName: "";
    }
  ];
  msg: "";
}> => {
  return http.post("/cms/auth/operation/baseList", params);
};

// APP用户分页查询
export const appUserInfoList = (
  params: SelectCmsAppUserPageParams
): Promise<SelectCmsAppUserPageRes> => {
  return http.post("/app/user/list", params);
};



// App用户增加余额
export const appEditAccount = (
  params: SelectCmsAppUserPageParams
): Promise<SelectCmsAppUserPageRes> => {
  return http.post("/app/user/editAccount", params);
};

// App用户修改
export const appEditUser = (
  params: any
): Promise<any> => {
  return http.post("/app/user/edit", params);
};

//App用户详情
export const appDetail = (id: string): Promise<any> => {
  return http.get(`/app/user/detail/${id}`);
};

// 赠送VIP权限取反（启用-禁用）
export const appUserInfoNegationGiveVip = (
  userId: string
): Promise<defaultRes> => {
  return http.post("/cms/auth/user/info/userInfo/negationGiveVip", userId, {
    headers: {
      "Content-Type": "text/plain",
    },
  });
};

// 置用户密码为手机号
export const appUserInfoResetAppUserPwd = (
  userId: string
): Promise<defaultRes> => {
  return http.post("/cms/auth/user/info/userInfo/resetAppUserPwd", userId, {
    headers: {
      "Content-Type": "text/plain",
    },
  });
};

// 查询用户账户信息
export const getOperationUserDetail = (
  userId: string
): Promise<GetUserAccountByIdRes> => {
  return http.get(`/cms/auth/user/info/getUserAccountById?userId=${userId}`);
};
// 查询用户认证信息
export const getOperationAuthDetail = (
  userId: string
): Promise<{
  code: number;
  data: {
    authStatus: string;
    certBackUrl: string;
    certFrontUrl: string;
    certId: string;
    certName: string;
    userId: string;
  };
  msg: string;
}> => {
  return http.get(`/cms/auth/user/info/queryUserAuth?userId=${userId}`);
};

// APP用户注销
export const appUserInfoDel = (userId: string): Promise<defaultRes> => {
  return http.delete(`/cms/auth/user/info/delete/${userId}`);
};

// 查询运营商财务按月统计数据
export const getOperationFinanceMonthPageList = (
  params: GetOperationFinanceMonthPageListParams
): Promise<GetOperationFinanceMonthPageListRes> => {
  return http.post(
    "/cms/auth/operation/data/getOperationFinanceMonthPageList",
    params
  );
};
// 查询运营商财务结算记录
export const getOperationFinanceCashOrderPageList = (params: {
  ypageNum: number;
  ypageSize: number;
}): Promise<GetOperationFinanceCashOrderPageListRes> => {
  return http.post(
    "/cms/auth/operation/data/getOperationFinanceCashOrderPageList",
    params
  );
};

// 运营商财务按月统计导出
export const operationFinanceMonthExport = (
  params: GetOperationFinanceMonthPageListParams
): Promise<defaultRes> => {
  return http.post(
    "/cms/auth/operation/data/operationFinanceMonthExport",
    params,
    {
      responseType: "blob",
    }
  );
};

// 查询运营商财务数据
interface GetOperationFinanceDataRes {
  code: number;
  data: {
    income: number;
    operatorId: string;
    operatorName: string;
    totalIncome: number;
  };
  msg: string;
}
export const getOperationFinanceData =
  (): Promise<GetOperationFinanceDataRes> => {
    return http.get("/cms/auth/operation/data/getOperationFinanceData");
  };

// 查询运营商门店明细
export const getOperationStorePageList = (
  params: GetOperationStorePageListParams
): Promise<GetOperationStorePageListRes> => {
  return http.post(
    "/cms/auth/operation/data/getOperationStorePageList",
    params
  );
};

// 查询运营商VIP收入明细
export const getOperationVipIncomePageList = (
  params: GetOperationVipIncomePageListParams
): Promise<GetOperationVipIncomePageListRes> => {
  return http.post(
    "/cms/auth/operation/data/getOperationVipIncomePageList",
    params
  );
};

// 运营商VIP收入明细导出
export const operationVipIncomeExport = (
  params: GetOperationVipIncomePageListParams
): Promise<defaultRes> => {
  return http.post(
    "/cms/auth/operation/data/operationVipIncomeExport",
    params,
    {
      responseType: "blob",
    }
  );
};

// 查询运营商机油收入明细
export const getOperationMerOilIncomePageList = (
  params: GetOperationMerOilIncomePageListParams
): Promise<GetOperationMerOilIncomePageListRes> => {
  return http.post(
    "/cms/auth/operation/data/getOperationMerOilIncomePageList",
    params
  );
};

// 运营商机油收入明细导出
export const operationMerOilIncomeExport = (
  params: GetOperationMerOilIncomePageListParams
): Promise<defaultRes> => {
  return http.post(
    "/cms/auth/operation/data/operationMerOilIncomeExport",
    params,
    {
      responseType: "blob",
    }
  );
};

// 查询运营商收入统计数据
// 响应接口
interface GetOperationIncomeDataRes {
  code: number;
  data: {
    currentMonthIncrement: number;
    currentWeekIncrement: number;
    lastDayIncrement: number;
    lastMonthIncrement: number;
    lastWeekIncrement: number;
    totalIncrement: number;
  };
  msg: string;
}
export const getOperationIncomeData =
  (): Promise<GetOperationIncomeDataRes> => {
    return http.get("/cms/auth/operation/data/getOperationIncomeData");
  };

// 查询运营商用户统计数据
// 响应接口
interface GetOperationIncomeDataRes {
  code: number;
  data: {
    currentMonthIncrement: number;
    currentWeekIncrement: number;
    lastDayIncrement: number;
    lastMonthIncrement: number;
    lastWeekIncrement: number;
    totalIncrement: number;
  };
  msg: string;
}
export const getOperationUserCountData =
  (): Promise<GetOperationIncomeDataRes> => {
    return http.get("/cms/auth/operation/data/getOperationUserCountData");
  };

// 查询运营商门店统计数据
// 响应接口
interface GetOperationIncomeDataRes {
  code: number;
  data: {
    currentMonthIncrement: number;
    currentWeekIncrement: number;
    lastDayIncrement: number;
    lastMonthIncrement: number;
    lastWeekIncrement: number;
    totalIncrement: number;
  };
  msg: string;
}
export const getOperationStoreCountData =
  (): Promise<GetOperationIncomeDataRes> => {
    return http.get("/cms/auth/operation/data/getOperationStoreCountData");
  };

// 管理员查询结算列表
export const listCashOrder = (
  params: ListCashOrderParams
): Promise<ListCashOrderRes> => {
  return http.post("/cms/auth/operation/cash/order/listCashOrder", params);
};

// 管理员查询结算列表
export const addCashOrder = (params: {
  amount: string;
  listVoucherUrl: Array<string>;
  operatorId: string;
}): Promise<defaultRes> => {
  return http.post("/cms/auth/operation/cash/order/addCashOrder", params);
};

// 提供给CMS使用-通过用户id查询用户变更信息
export const listUserAccountChangesById = (params: {
  ypageNum: number;
  ypageSize: number;
  userId: string;
}): Promise<ListUserAccountChangesByIdRes> => {
  return http.post("/cms/auth/user/info/listUserAccountChangesById", params);
};


// 查询商家系统订单评论信息列表
export const apiListMerOrderComment = (params:ApiListMerOrderCommentParams): Promise<ApiListMerOrderCommentRes> => {
  return http.post("/cms/mer/goods/order/comment/apiListMerOrderComment", params);
};

// 查询商家系统订单评论信息列表
export const checkComment = (params:{
  id: string,
  status: string
}): Promise<defaultRes> => {
  return http.post("/cms/mer/goods/order/comment/checkComment", params);
};

// app版本列表分页查询
export const getVersionList = (params:any): Promise<any> => {
  return http.post("/app/version/list", params);
};


// app版本新增
export const addAppVersion = (params:any): Promise<any> => {
  return http.post("/app/version/add", params);
};

// app版本编辑
export const editAppVersion = (params:any): Promise<any> => {
  return http.post("/app/version/edit", params);
};

// 推荐人变更

export const changeParent = (params: {
  mobile: string;
  userId: string;
}): Promise<any> => {
  return http.post("/app/user/changeParent", params);
};


// 网点列表分页查询
export const listOutlets = (params:any): Promise<any> => {
  return http.post("/outlets/info/list", params);
};


// 网点新增
export const addOutlets = (params:any): Promise<any> => {
  return http.post("/outlets/info/add", params);
};

// 网点编辑
export const editOutlets = (params:any): Promise<any> => {
  return http.post("/outlets/info/edit", params);
};

// 网点删除
export const deleteOutlets = (id:string): Promise<any> => {
  return http.post(`/outlets/info/delete/${id}`);
};

// 网点详情
export const getOutletsDetail = (id:string): Promise<any> => {
  return http.get(`/outlets/info/detail/${id}`);
};



// 网点列表
export const getOutletsBaseList = (params:any): Promise<any> => {
  return http.post(`/outlets/info/baseList`, params);
};

// App用户补贴
export const editAppUserBuTie = (params:any): Promise<any> => {
  return http.post(`/app/user/editAppUserBuTie`, params);
};

// 链路详情
export const getLinkDetail = (id:string): Promise<any> => {
  return http.get(`/app/user/getRelation/${id}`);
};



