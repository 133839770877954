import { setIcons } from '@/utils/common';
import { Button, Space, Switch, Tag } from 'antd'
// import { setIcons } from '@/utils/icon'
export function columns (props: any) {
  const { providers_level, setOpen, setModalFormData, outDetail, changeSubsidy, setuserOutOpen, setUserOutFormData, userOutFormData, linkDetail } = props
  return [
    {
      title: "用户ID",



    dataIndex: "userBusinessId",
    key: "userBusinessId",
  },
  {
    title: "用户头像",
    dataIndex: "avatar",
    key: "avatar",
    render: (data: string) =>
      data && <img style={{ height: "32px" }} src={data} alt="" />,
  },
  {
    title: "昵称",
    dataIndex: "nickName",
    key: "nickName",
  },
  {
    title: "账号",
    dataIndex: "mobile",
    key: "mobile",
  },
  {
    title: "注册方式",
    dataIndex: "userRegType",
    key: "userRegType",
    render: (val: number | string) => (
      <Tag color={val == 0 ? "blue" : "green"}>
        {val == 0 ? "手机号注册" : "邮箱注册"}
      </Tag>
    ),
  },
  {
    title: "佣金金额",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "转赠金额",
    dataIndex: "transferAmount",
    key: "transferAmount",
  },
  // {
  //   title: "积分",
  //   dataIndex: "integral",
  //   key: "integral",
  // },
  {
    title: "充电收益",
    dataIndex: "chargingAmount",
    key: "chargingAmount",
  },
  {
    title: "代理收益",
    dataIndex: "merchantAmount",
    key: "merchantAmount",
  },
  // {
  //   title: "团队总设备台数",
  //   dataIndex: "teamDeviceNum",
  //   key: "teamDeviceNum",
  // },
  {
    title: "个性签名",
    dataIndex: "personalizedSignature",
    key: "personalizedSignature",
  },
  // {
  //   title: "推荐人ID",
  //   dataIndex: "recommenderUserId",
  //   key: "recommenderUserId",
  // },
  {
    title: "推荐人Id",
    dataIndex: "recommendUserId",
    key: "recommendUserId",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    key: "createTime",
    // render: (val: string) => <div>{getTimeFormat(val)}</div>
  },
  {
    title: "用户等级",
    dataIndex: "userLevel",
    key: "userLevel",
    render: (val: string) => (
      <Tag color="blue">
        {
          providers_level?.find((i: { value: string }) => i.value === val)
            ?.label
        }
      </Tag>
    ),
  },
  // {
  //   title: "状态",
  //   dataIndex: "userStatus",
  //   key: "userStatus",
  //   render: (val: string, rows: any) => (
  //     <Switch onChange={(e)=>changeUserStatus(e,rows)} checked={val === "0"} />
  //   ),
  // },
  {
    title: "用户类型",
    dataIndex: "userType",
    key: "userType",
    render: (val: string) => (
      <Tag color="blue">{val === "0" ? "管理" : "普通"}</Tag>
    ),
  },
  {
    title: "补贴",
    dataIndex: "skipBuTie",
    key: "skipBuTie",
    render: (val: number, rows: any) => (
     <Switch onChange={(e)=>changeSubsidy(rows)} checked={val === 1} />
    ),

  },



  {
    title: "操作",
    dataIndex: "userId",
    key: "userId",
    fixed: "right",
    render: (val: string, rows: any) => (
      <Space>
        {rows.userStatus === "0" ? (
          <>
            <Button
              size="small"
              icon={setIcons("CloudUploadOutlined")}
              type="text"
              onClick={(e) => {
                setOpen(true);
                setModalFormData({
                  userId: val,
                });
              }}
            >
              添加金额
            </Button>
            <Button
              size="small"
              icon={setIcons("EditOutlined")}
              type="text"
              onClick={() => {
                outDetail(val);
              }}

            >
              修改
            </Button>
            <Button
              size="small"
              icon={setIcons("LinkOutlined")}
              type="text"
              onClick={(e) => {
                linkDetail(val);
              }}

            >
              链路详情
            </Button>
            {/* <Button
              size="small"
              icon={setIcons("StopOutlined")}
              type="text"
              onClick={(e) => {
                offUser(val);
              }}
            >
              注销
            </Button> */}
            {/* <Button
              size="small"
              icon={setIcons("ContactsOutlined")}
              type="text"
              onClick={() => {
                authDetail(val);
              }}
            >
              认证信息
            </Button> */}
          </>
        ) : (
          <></>
        )}

        <Button
          size="small"
          icon={setIcons("SnippetsOutlined")}
          type="text"
          onClick={() => {
            setuserOutOpen(true);
            setUserOutFormData({
              ...userOutFormData,
              userId: val,
            });
          }}
        >
          推荐人变更
        </Button>
      </Space>
    ),
  },
];
}


export const linkColumns = [
  {
    title: "用户ID",
    dataIndex: "userId",
    key: "userId",
  },
  {
    title: "昵称",
    dataIndex: "nickName",
    key: "nickName",
  },
  {
    title: "手机号",
    dataIndex: "mobile",
    key: "mobile",
  },
  // {
  //   title: "关系路径",
  //   dataIndex: "recommenderPath",
  //   key: "recommenderPath",
  // },
  {
    title: "推荐者Id",
    dataIndex: "recommendUserId",
    key: "recommendUserId",
  },
  {
    title: "用户等级",
    dataIndex: "userLevel",
    key: "userLevel",
  },
  {
    title: "备注",
    dataIndex: "remark",
    key: "remark",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    key: "createTime",
  }

]