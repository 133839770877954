import http from "@/utils/request";

// 订单列表分页查询
export const shoppingOrderList = (
  params: any
): Promise<any> => {
  return http.post("/mall/order/list", params);
};
export const orderDetail = (id: string): Promise<any> => {
  return http.get(`/device/info/detail/${id}`);
};

// 商城订单导出
export const shoppingOrderExport = (
  params: SelectCmsShoppingOrderPageParams
): Promise<defaultRes> => {
  return http.post("/mall/order/export", params, {
    responseType: "blob",
  });
};
// 权益包核销
export const benefitUse = (params: {
  merId: string;
  orderId: string;
}): Promise<defaultRes> => {
  return http.post("/cms/auth/shopping/order/benefitUse", params);
};

// CMS使用——VIP订单退款
export const cmsRefund = (params: {
  orderId: string;
  refundAmount: number;
  refundReason?: string;
}): Promise<defaultRes> => {
  return http.post("/cms/auth/shopping/order/cmsRefund", params);
};
// 商品设备修改
export const doDeliver = (params: Record<string, any>): Promise<defaultRes> => {
  return http.post("/device/info/edit", params);
};


// 商品类型列表分页查询
export const categoryList = (
  params: any
): Promise<any> => {
  return http.post("/mall/category/list", params);
};

// 商品类型新增
export const categoryAdd = (
  params: any
): Promise<any> => {
  return http.post("/mall/category/add", params);
};

// 商品类型编辑
export const categoryEdit = (
  params: any
): Promise<any> => {
  return http.post("/mall/category/edit", params);
};

// 商品类型详情
export const categoryDetail = (id: string): Promise<any> => {
  return http.get(`mall/category/detail/${id}`);
};

// vip商品列表分页查询
export const vipList = (
  params: any
): Promise<any> => {
  return http.post("/mall/product/list", params);
};

// VIP商品新增
export const vipAdd = (params: any): Promise<defaultRes> => {
  return http.post("/mall/product/add", params);
};

// VIP商品详情
export const vipDetail = (id: string): Promise<defaultRes> => {
  return http.get(`/mall/product/detail/${id}`);
};

// VIP商品编辑
export const vipEdit = (params: any): Promise<defaultRes> => {
  return http.post("/mall/product/edit", params);
};


// Vip订单分佣
export const getRewardDetail = (params: any): Promise<GetVipOrderRewardsRes> => {
  return http.post(`/user/account/changeList`,params);
};

// 用户提现列表分页查询
export const userOrderList = (params: any): Promise<any> => {
  return http.post("/user/cash/order/list", params);
};

// 用户商户提现审核
export const userOrderAudit = (params:any): Promise<any> => {
  return http.post("/user/cash/order/audit", params);
};

// 状态同步
export const orderTranslate = (cashOrderId: string): Promise<defaultRes> => {
  return http.get(`/cms/auth/user/cash/order/orderTranslate/${cashOrderId}`);
};

// 提现订单导出
export const orderExport = (params: any): Promise<defaultRes> => {
  return http.post(`/user/cash/order/export` , params,{responseType: "blob"});
};


// 设备列表分页查询
export const deviceList = (params: any): Promise<Record<string, any>> => {
  return http.post(`/device/info/list` , params);
};

// 设备详情
export const deviceDetail = (id: string): Promise<Record<string, any>> => {
  return http.get(`/device/info/detail/${id}`);
};

// 商品删除
export const vipDelete = (id: string): Promise<Record<string, any>> => {
  return http.post(`/mall/product/delete/${id}`);
};
// 批量修改
export const editBatch = (params: any): Promise<Record<string, any>> => {
  return http.post(`/device/info/editBatch`, params);
};
