import { Tag } from "antd";

export const rewardDetailColumns = [
  {
    title: "资金走向类型",
    dataIndex: "accountTrendStr",
    key: "accountTrendStr",
    render: (val: string) => <Tag color="blue">{val}</Tag>
  },
  {
    title: "金额",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "手机号",
    dataIndex: "mobile",
    key: "mobile",
  },
  {
    title: "分润类型",
    dataIndex: "businessTypeStr",
    key: "businessTypeStr",
  },
  // {
  //   title: "分润类型",
  //   dataIndex: "businessTypeStr",
  //   key: "businessTypeStr",
  // },
  {
    title: "用户名/运营中心名称",
    dataIndex: "nickName",
    key: "nickName",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    key: "createTime",
  },
]
